<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">试卷列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="请输入试卷名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="是否公开" class="searchboxItem ci-full">
              <span class="itemLabel">是否公开:</span>
              <el-select v-model="isPublic" placeholder="请选择" size="small">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:5px">
            <div title="销售状态" class="searchboxItem ci-full">
              <span class="itemLabel">销售状态:</span>
              <el-select v-model="saleState" placeholder="请选择" size="small">
                <el-option
                  v-for="item in salestatelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed width="100px"/>
              <el-table-column
                label="试卷名称"
                align="left"
                prop="paperName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="140"
              >
                <template slot-scope="scope">{{scope.row.trainTypeNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="岗位类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.postName || '--'}}</template>
              </el-table-column>
              <el-table-column label="行业类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.industryNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="职业/工种" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.occupationNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="培训等级" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{scope.row.trainLevelName || '--'}}</template>
              </el-table-column>
              <el-table-column
                label="题目数量"
                align="right"
                prop="questionNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="总分数"
                align="right"
                prop="paperTotalScore"
                show-overflow-tooltip
              />
              <el-table-column label="通过分数" align="right" prop="paperScore" show-overflow-tooltip />
              <el-table-column
                label="是否公开"
                align="center"
                show-overflow-tooltip
                prop="paperIsPublic"
                minWidth="120"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-switch
                    class="selStyle"
                    v-model="scope.row.paperIsPublic"
                    width="40"
                    @change="getSwitch(scope.row.paperId,scope.row.paperIsPublic)"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="销售状态"
                align="center"
                show-overflow-tooltip
                prop="salesState"
                minWidth="120"
                fixed="right"
              >
                <template slot-scope="scope">{{$setDictionary("SELLSTATE", scope.row.salesState)}}</template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" align="center" width="200px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="showPaper(scope.row.paperId)"
                  >预览</el-button>
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="exportPaper(scope.row.paperId)"
                  >导出Excel</el-button>
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="exportWord(scope.row.paperId)"
                  >导出word</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import PaperShow from "@/views/resourse/popup/PaperShow";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/Testquestionbank",
  components: {
    Empty,
    PageNum,
    tree,
    PaperShow
  },
  mixins: [List],
  data() {
    return {
      paperName: "",
      isPublic: "",
      saleState: "",
      params: {},
      ruleForm: {
        Trainingtype: ""
      },
      salestatelist: [],
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ]
    };
  },
  created() {
    this.getcompanytypeList();
  },
  computed: {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperIsPublic: this.isPublic
      };
      if (this.paperName) {
        params.paperName = this.paperName;
      }
      if (this.saleState) {
        params.salesState = this.saleState;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/paper/queryRunList",
        params,
        pageNum
      });
    },
    getSwitch(paperId, paperIsPublic) {
      this.$post("/biz/paper/modifyIsPublic", { paperId, paperIsPublic })
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功"
            });
            this.getData(-1);
          }
        })
        .catch(err => {
          return;
        });
    },
    showPaper(paperId) {
      this.$refs.PaperShow.showPopUp(paperId); // 打开弹窗预览试题
    },
    /* 导出 */
    exportPaper(paperId) {
      this.$post('/biz/paper/exportPaper',{
        paperId
      }).then(ret => {
        window.open(ret.data)
      }).catch(err => {
        return;
      })
    },
    /* 导出 - word */
    exportWord(paperId) {
      this.$post('/biz/paper/exportPaperWord',{
        paperId
      }).then(ret => {
        window.open(ret.data)
      }).catch(err => {
        return;
      })
    },   
    getcompanytypeList() {
      const sellstatelist = this.$setDictionary("SELLSTATE", "list");
      const list = [{ value: "", label: "全部" }];
      for (const key in sellstatelist) {
        list.push({
          value: key,
          label: sellstatelist[key]
        });
      }
      this.salestatelist = list;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less">
</style>
