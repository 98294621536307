<template>
  <el-dialog
    title="试卷预览"
    :visible.sync="dialogVisible"
    top="4%"
    width="80%"
    :center="true"
    :before-close="doClose"
    :modal="modal"
  >
    <div class="pageContol listWrap templateList dialogPaper" style="height:600px">
      <div class="framePage">
        <div class="framePage-body">
          <div class="searchboxs">
            <h3>试卷基本信息</h3>
              <div style="display: flex;justify-content: space-around;width: 100%;">
                <div style="display:flex;align-items: center;">
                  <p>试卷名称：</p>
                  <span>{{ ruleForms.paperName || '' }}</span>
                </div>
                <div style="display:flex;align-items: center;">
                  <p>试卷总分：</p>
                  <span>{{ ruleForms.paperTotalScore || '' }}</span>
                </div>
                <div style="display:flex;align-items: center;">
                  <p>通过分数：</p>
                  <span>{{ ruleForms.paperScore || '' }}</span>
                </div>
                <div style="display:flex;align-items: center;">
                  <p>是否在平台销售：</p>
                  <span>{{ ruleForms.salesState == 10 ? "否" : "是" }}</span>
                </div>
                <div style="display:flex;align-items: center;">
                  <p>培训类型：</p>
                  <span>{{ ruleForms.trainTypeNamePath || '' }}</span>
                </div>
              <!-- <el-form  label-width="180px">
                <el-form-item label="试卷名称：" >
                  <p>{{ ruleForms.paperName || '' }}</p>
                </el-form-item>
                <el-form-item label="试卷总分：" >
                  <p>{{ ruleForms.paperTotalScore || ''}}</p>
                </el-form-item>
                <el-form-item label="通过分数：" >
                  <p>{{ ruleForms.paperScore || ''}}</p>
                </el-form-item>
                <el-form-item label="是否在平台销售：" >
                  <p>{{ ruleForms.salesState == 10 ? "否" : "是" }}</p>
                </el-form-item>
                <el-form-item label="培训类型:" >
                  <p>{{ ruleForms.trainTypeNamePath }}</p>
                </el-form-item>
              </el-form> -->
            </div>
          </div>
          <div class="framePage-scroll flexdc">
            <div class="bottom searchboxs" style="padding-top:0;">
              <h3>试卷内容信息</h3>
              <div class="flexcb">
                <el-form :inline="true">
                  <el-form-item label="题目内容:" style="margin-right:20px">
                    <el-input v-model="topiccontent"></el-input>
                  </el-form-item>
                  <el-form-item label="题目类型:" style="margin-right:20px">
                    <el-select v-model="TopictypeVal" placeholder="请选择">
                      <el-option
                        v-for="item in Topictype"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button style="padding:8px 20px" class="bgc-bv" @click="chaxun">查询</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="ovy-a flex1" id="list-box">
              <div class="list" v-for="item in tableData" :key="item.questionId">
                <div class="list-item df">
                  <span
                    class="tixing"
                    :class="{
                      tixing1: item.questionType == 0,
                      tixing2: item.questionType == 1,
                      tixing3: item.questionType == 2,
                      tixing4: item.questionType == 3
                    }"
                  >
                    {{
                    $setDictionary("QUESTIONTYPE", item.questionType)
                    }}
                  </span>
                  <div class="subject flex1">
                    <div class="subject-title">
                      <span>{{ item.questionTitle }}</span>
                      <span class="number">(题目分值：{{ item.questionScore }})</span>
                    </div>
                    <div class="option" v-if="item.questionType == 0">
                      <span>A.{{ item.questionItem1 }}</span>
                      <span>B.{{ item.questionItem2 }}</span>
                      <span v-if="item.questionItem3">C.{{ item.questionItem3 }}</span>
                      <span v-if="item.questionItem4">D.{{ item.questionItem4 }}</span>
                      <span v-if="item.questionItem5">E.{{ item.questionItem5 }}</span>
                      <span v-if="item.questionItem6">F.{{ item.questionItem6 }}</span>
                    </div>
                    <div class="option" v-if="item.questionType == 1">
                      <span>A.{{ item.questionItem1 }}</span>
                      <span>B.{{ item.questionItem2 }}</span>
                      <span>C.{{ item.questionItem3 }}</span>
                      <span>D.{{ item.questionItem4 }}</span>
                      <span v-if="item.questionItem5">E.{{ item.questionItem5 }}</span>
                      <span v-if="item.questionItem6">F.{{ item.questionItem6 }}</span>
                    </div>
                    <div class="option" v-if="item.questionType == 2"></div>
                    <div class="analysis">正确答案：{{ item.questionAnswer }}</div>
                    <div>
                      答案解析：
                      <span class="analysis" v-html="item.questionAnalysis">
                        {{
                        item.questionAnalysis
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "parperShow",
  mixins: [List],
  components: {
    PageNum
  },
  props: {
    modal: {
      type: Boolean,
      default: function() {
        return true;
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      editBtn: true, //
      paperList: [],
      form: {
        type: "1",
        radio: null,
        checkList: [],
        optionList: [
          { mins: "", state: false },
          { mins: "", state: false },
          { mins: "", state: false },
          { mins: "", state: false }
        ],
        testnum: null,
        selectState: true
      },
      parperId: "",
      ruleForms: {},
      datatreeList: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      Topictype: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "0",
          label: "单选题"
        },
        {
          value: "1",
          label: "多选题"
        },
        {
          value: "2",
          label: "判断题"
        },
        {
          value: "3",
          label: "填空题"
        }
      ],
      TopictypeVal: "",
      topiccontent: "",
      subjectlist: [] // 题目数据
    };
  },
  computed: {},
  methods: {
    showPopUp(id,projectCourseId) {
      this.dialogVisible = true;
      this.parperId = id;
      this.getData();
      this.getEdit(this.parperId,projectCourseId);
    },
    //
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperId: this.parperId
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.topiccontent) {
        params.questionTitle = this.topiccontent;
      }

      if (this.dialogVisible) {
        this.doFetch({
          url: "/biz/paper/queryQuestionList",
          params,
          pageNum
        });
      }
    },
    chaxun() {
      this.getData(-1);
    },
    getEdit(paperId,projectCourseId) {
      this.$post("/biz/paper/getPaperInfo", {
        paperId,
        projectCourseId
      }).then(ret => {
        this.ruleForms = ret.data;
        this.ruleForms.trainTypeNamePath = ret.data.trainTypeNamePath.replace(
          ",",
          "/"
        );
      });
    },
    doClose() {
      this.dialogVisible = false;
      this.TopictypeVal = '';
      this.topiccontent = '';
    },
    // tab回传
    tableBack(id) {
      this.parentPsBack(id);
    },
    formBack(id) {
      this.parentPsBack(id);
    },
    // 给父组件传参
    parentPsBack() {
      // this.$emit("eventBus", id);
      this.doClose();
    }
  }
};
</script>
<style lang="less" scoped>
.dialogPaper {
  .el-form {
     display:flex;
      .el-form-item {
        display:flex
    }
  }
 
}
  
</style>
<style lang="less" scoped>
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: 9.375rem;
        }
      }
    }
  }
}
.searchboxs {
  padding: 0.9rem 0 0 1.775rem;
  h3 {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 1.4rem;
    &::before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -17px;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
</style>
